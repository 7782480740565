import React from 'react';

const Holders = ({ token }) => {

    const size = (token.currentBondCurve / 100) * 250;

    const sliceStyle = {
        position: 'absolute',
        width: '50%',
        height: '100%',
        clip: 'rect(0, 300px, 300px, 0)',
        border: '1px solid grey', // 1px line for division
        borderRadius: '50%',
        transformOrigin: '100% 50%',
    };

    const slices = token.holders.map((_, index) => (
        <div key={index} style={{ ...sliceStyle, transform: `rotate(${(360 / token.holders.length) * index}deg)` }}></div>
    ));

    return (
        <div style={{ position: 'relative', width: size, height: size, zIndex: -1, borderRadius: '50%', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', transform: 'rotate(-90deg)' }}>
                {slices}
            </div>
        </div>
    );
};

export default Holders;
