import React from 'react';
import './TokenDetails.css';

const TokenDetails = ({ token }) => {
  const totalSupply = 1073000000; // Total supply

  // Calculate top 10 holders and their percentage of the total supply
  const topHolders = [...token.holders]
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 10)
    .map(holder => ({
      ...holder,
      percentage: ((holder.amount / totalSupply) * 100).toFixed(2)
    }));

  return (
    <div className="token-details">
      <div className="token-details-title">Token Information</div>
      <div className="token-details-section">
        <div className="token-details-item">
          <strong>Mint:</strong> {token.mint.slice(0, 6)}...{token.mint.slice(-4)}
        </div>
        <div className="token-details-item">
          <strong>Created:</strong> {new Date(token.created).toLocaleString()}
        </div>
        <div className="token-details-item">
          <strong>Swaps:</strong> {token.swaps}
        </div>
        <div className="token-details-item">
          <strong>Current Bond Curve:</strong> {token.currentBondCurve}%
        </div>
        <div className="token-details-item">
          <strong>Dev Amount:</strong> {token.dev.amount}
        </div>
      </div>
      <div className="token-details-title">Top 10 Holders</div>
      <div className="token-details-section">
        {topHolders.map((holder, index) => (
          <div key={index} className="token-details-item">
            <strong>{index + 1}. {holder.mint.slice(0, 6)}...{holder.mint.slice(-4)}:</strong> {holder.amount.toLocaleString()} ({holder.percentage}%)
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenDetails;
