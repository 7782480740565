import React, { useEffect, useState } from 'react';
import './Dev.css';

export default function Dev({ token }) {
    const [isPulsing, setIsPulsing] = useState(false);

    const current_size = (Math.floor(token.dev.percent) / 100) * 215

    useEffect(() => {
        setIsPulsing(true);
        const timer = setTimeout(() => setIsPulsing(false), 300); // Pulse duration

        return () => clearTimeout(timer);
    }, [token.dev.amount]);

    return (
        <div className="dev-wrapper">
            <div className={`circle ${isPulsing ? 'pulse' : ''}`} style={{ 
                width: `${current_size}px`, 
                height: `${current_size}px`, 
                border: token.dev.amount < 0 ? '1px solid red' : '2px solid yellow',
                boxShadow: token.dev.amount < 0 ? '0 0 8px red' : '0 0 10px yellow'
            }}>
            </div>
        </div>
    );
}
