import React from 'react';
import './BlurOverlay.css';
import logo from '../Assets/safeape-logo.png';

const BlurOverlay = () => {
  return (
    <div className="blur-overlay">
      <div className="overlay-content">
        <img src={logo} alt="SafeApe Logo" className="overlay-icon" />
        <h1 className="app-title">SafeApe</h1>
        <p className="app-description">Loading...</p>
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default BlurOverlay;
