import React from 'react';
import './InitialBuy.css';

export default function InitialBuy({ token }) {
    const initial_size = 0 + ((token.initialBondCurve / 100) * (250 - 0));
    return (
        <div className="initial-buy-wrapper">
            <div className="initial-circle" style={{ width: initial_size, height: initial_size }}></div>
        </div>
    );
}
