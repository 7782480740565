import { useEffect, useState } from 'react';
import './BondCurve.css';

export default function BondCurve({ token, zoom }) {
    const [isPulsing, setIsPulsing] = useState(false);

    const size = (token.currentBondCurve / 100) * 250;

    const minBorderThickness = 1; // Minimum border thickness
    const maxBorderThickness = 10; // Maximum border thickness
    const minSwaps = 0; // Minimum number of swaps
    const maxSwaps = 1000; // Maximum number of swaps

    // Calculate the border thickness based on the number of swaps using linear interpolation
    const borderThickness = Math.min(
        maxBorderThickness,
        minBorderThickness + ((token.swaps - minSwaps) / (maxSwaps - minSwaps)) * (maxBorderThickness - minBorderThickness)
    );

    // Ensure the border thickness is a whole number
    const roundedBorderThickness = Math.round(borderThickness);

    useEffect(() => {
            setIsPulsing(true);
            setTimeout(() => setIsPulsing(false), 300); 
    }, [token.swaps]);

    return (
        <div className={`bond-curve ${isPulsing ? 'pulse' : ''}`}
            style={{
                width: size,
                height: size,
                border: token.dev.percent > 1 ? `${roundedBorderThickness}px solid #ff00ff` : `${roundedBorderThickness}px solid white`,
                boxShadow: token.dev.percent > 1 ? '0 0 8px #ff00ff' : '0 0 5px grey' /* Glowing effect similar to Nucleus */
            }}>
        </div>
    );
}
