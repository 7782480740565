import React from 'react';
import './TopMenu.css';
import logo from '../Assets/safeape-logo.png';

const TopMenu = () => {
  return (
    <div className="top-menu">
      {/* <div className="hamburger-menu">
        <div className="hamburger-icon">&#9776;</div>
      </div> */}
      <div className="logo">
        <img src={logo} alt="SafeApe Logo" className="logo-icon" />
        <span className="logo-text">SafeApe</span>
      </div>
      <div className="connect-button">
        {/* <button>Connect</button> */}
      </div>
    </div>
  );
};

export default TopMenu;
