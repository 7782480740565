import React, { useState, useEffect } from 'react';
import './Token.css';
import InitialBuy from './Initial/InitialBuy';
import { FaChartLine, FaUsers, FaUserTie, FaExchangeAlt, FaClock, FaStar } from 'react-icons/fa';
import Dev from './Dev/Dev';
import BondCurve from './BondCurve';
import Holders from './Holders';
import pump_logo from '../Assets/pump-logo.png';
import photonLogo from '../Assets/photon-logo.svg';
import TokenDetails from './TokenDetails';

export default function Token({ token, isRemoving, isSolo, onSolo }) {
  const [tokenAge, setTokenAge] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [topHoldersPercentage, setTopHoldersPercentage] = useState(0);

  useEffect(() => {
    const updateAge = () => {
      const createdDate = new Date(token.created);
      const now = new Date();
      const diff = now - createdDate;

      const hours = Math.floor(diff / 1000 / 60 / 60);
      const minutes = Math.floor(diff / 1000 / 60) % 60;
      const seconds = Math.floor(diff / 1000) % 60;

      setTokenAge(`${hours}h ${minutes}m ${seconds}s`);
      setIsNew(diff < 60000); // Token is new if it was created within the last minute
    };

    const timerId = setInterval(updateAge, 1000);
    updateAge();  // Initial update

    return () => clearInterval(timerId);
  }, [token.created]);

  useEffect(() => {
    const topHolders = [...token.holders]
      .sort((a, b) => b.amount - a.amount)
      .slice(0, 10);
    
    const totalTopHoldersAmount = topHolders.reduce((sum, holder) => sum + holder.amount, 0);
    const percentage = Math.floor((totalTopHoldersAmount / token.vTokensInBondingCurve) * 100);
    
    setTopHoldersPercentage(percentage);
  }, [token.holders, token.vTokensInBondingCurve]);

  const selectToken = () => {
    console.log(token);
  };

  const openPumpFun = () => {
    const url = `https://pump.fun/${token.mint}`;
    window.open(url, '_blank');
  };

  const openPhoton = () => {
    const url = `https://photon-sol.tinyastro.io/en/lp/${token.mint}`;
    window.open(url, '_blank');
  };

  const getDevIconClass = (percent) => {
    if (percent < 1) return 'black';
    if (percent <= 4) return 'green';
    if (percent <= 7) return 'yellow';
    if (percent <= 10) return 'orange';
    return 'red';
  };

  const getTopHoldersIconClass = (percentage) => {
    if (percentage < 1) return 'icon-black';
    if (percentage < 10) return 'icon-green';
    if (percentage < 20) return 'icon-orange';
    return 'icon-red';
  };

  return (
    <div className={`card-container-wrapper ${isRemoving ? 'removal' : ''}`}>
      <div onClick={selectToken} className="card-container">
        {isNew && <div className='new-tag'>New</div>}
        {token.dev.percent > 10 && <div className='rug-tag'>Rug</div>}
        <div className='card-top'>
          <div className='stat'>
            <FaChartLine className="filter-icon" title="Filter by Bond Curve"/>
            <div>{Math.floor(token.currentBondCurve)}%</div>
          </div>
          <div className='token-mint'>
            {token.mint.slice(0, 6)}
            <div className="logo-buttons">
              <div className="logo" onClick={openPumpFun}>
                <img src={pump_logo} alt="Pump Fun" className="logo-img" />
              </div>
              <div className="logo" onClick={openPhoton}>
                <img src={photonLogo} alt="Photon" className="logo-img photon" />
              </div>
            </div>
          </div>
          <div className='stat'>
            <FaUsers className="filter-icon" title="Filter by Holders"/>
            <div>{token.holders.length}</div>
          </div>
        </div>
        <div className="token-container">
          <Holders token={token} />
          <BondCurve token={token} />
          <Dev token={token} />
          <InitialBuy token={token} />
        </div>
        <div className="card-bottom">
          <div className="dev-info">
            <FaUserTie
              className={`dev-icon ${getDevIconClass(token.dev.percent)}`}
              title="Developer Status"
            />
            <div className="dev-percent">{token.dev.percent.toFixed(1)}%</div>
          </div>
          <div className="swaps-info">
            <FaExchangeAlt className="swaps-icon" title="Swaps"/>
            <div className="swaps-count">{token.swaps}</div>
          </div>
          <div className="token-age">
            <div className="age-icon">
              <FaClock className="age-icon" title="Age of Token"/>
            </div>
            <div className="age">{tokenAge}</div>
          </div>
          <div className="top-holders-percentage">
            <FaStar className={`top-holders-icon ${getTopHoldersIconClass(topHoldersPercentage)}`} title="Top 10 Holders"/>
            <div className="top-holders-value">{topHoldersPercentage}%</div>
          </div>
          <button className={`solo-button ${isSolo ? 'solo-active' : ''}`} onClick={(e) => { e.stopPropagation(); onSolo(token.mint); }}>S</button>
        </div>
      </div>
      {isSolo && <TokenDetails token={token} />}
    </div>
  );
}
