import React from 'react';
import './DisclaimerPopup.css';

function DisclaimerPopup({ onClose }) {
  return (
    <div className="disclaimer-popup">
      <div className="disclaimer-content">
        <h2>Disclaimer</h2>
        <p>SafeApe is still an experimental tool and is not liable for any losses. The data provided may not be 100% accurate, so users should do their own research. By using this website, you agree to these terms.</p>
        <button onClick={onClose}>Enter</button>
      </div>
    </div>
  );
}

export default DisclaimerPopup;
