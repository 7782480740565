import React, { useEffect, useState } from 'react';
import Token from './Components/Token';
import './App.css';
import TopMenu from './Components/TopMenu';
import FilterMenu from './Components/FilterMenu';
import BlurOverlay from './Components/BlurOverlay';
import DisclaimerPopup from './Components/DisclaimerPopup';

function App() {
  const [allTokens, setAllTokens] = useState([]); // Keep track of all tokens for filtering
  const [filteredTokens, setFilteredTokens] = useState([]); // Tokens to display
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [showDisclaimer, setShowDisclaimer] = useState(true); // Disclaimer popup state
  const [tokensToRemove, setTokensToRemove] = useState([]); // Tokens to be removed with animation
  const [soloToken, setSoloToken] = useState(null); // Token to be soloed

  useEffect(() => {
    const ws = new WebSocket('wss://socket.safeape.fun/');
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        const tokensArray = Object.values(data);

        // Determine which tokens are no longer present
        const tokensToRemoveNow = allTokens.filter(
          token => !tokensArray.some(newToken => newToken.mint === token.mint)
        );

        // Trigger removal animation for these tokens
        setTokensToRemove(tokensToRemoveNow.map(token => token.mint));

        // Set timeout to update tokens after animation duration
        setTimeout(() => {
          setAllTokens(tokensArray); // Update all tokens
          setTokensToRemove([]); // Clear the tokens to remove list
        }, 500); // Duration of the animation
      } catch (error) {
        console.error('Failed to parse JSON:', error);
      }
    };
    return () => ws.close();
  }, [allTokens]);

  useEffect(() => {
    if (allTokens.length > 0) {
      setIsLoading(false);
    }
  }, [allTokens]);

  const closeDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const soloTokenHandler = (mint) => {
    setSoloToken(soloToken === mint ? null : mint);
  };

  const displayedTokens = soloToken ? allTokens.filter(token => token.mint === soloToken) : filteredTokens;

  return (
    <div className="app-container">
      {isLoading && <BlurOverlay />}
      {showDisclaimer && <DisclaimerPopup onClose={closeDisclaimer} />}
      <TopMenu />
      <FilterMenu 
        allTokens={allTokens} 
        setFilteredTokens={setFilteredTokens} 
      />
      <div className="tokens-container">
        {displayedTokens.map((token) => (
          <Token 
            key={token.mint} 
            token={token} 
            isRemoving={tokensToRemove.includes(token.mint)}
            isSolo={soloToken === token.mint}
            onSolo={soloTokenHandler}
          />
        ))}
      </div>
      {/* <footer className="footer">© 2024 SafeApe. All rights reserved.</footer> */}
    </div>
  );
}

export default App;
