import React, { useState, useEffect } from 'react';
import { FaClock, FaUsers, FaUserTie, FaCoins, FaChartLine, FaExchangeAlt } from 'react-icons/fa';
import './FilterMenu.css';

const FilterMenu = ({ allTokens, setFilteredTokens }) => {
  const [isNewestFirst, setIsNewestFirst] = useState(false);
  const [filterHolders, setFilterHolders] = useState(false);
  const [filterDevAmount, setFilterDevAmount] = useState(false);
  const [filterBondCurve, setFilterBondCurve] = useState(false);
  const [filterSwaps, setFilterSwaps] = useState(false);
  const [filterTime, setFilterTime] = useState(false);
  const [searchMint, setSearchMint] = useState('');

  useEffect(() => {
    filterAndSortTokens();
  }, [isNewestFirst, filterHolders, filterDevAmount, filterBondCurve, filterSwaps, filterTime, searchMint, allTokens]);

  const filterAndSortTokens = () => {
    let result = [...allTokens];

    if (filterDevAmount) {
      result = result.filter(token => token.dev.amount > 1);
    }
    if (filterHolders) {
      result = result.filter(token => token.holders.length > 0);
    }
    if (searchMint) {
      result = result.filter(token => token.mint.includes(searchMint));
    }

    const sorts = [];
    if (filterTime && isNewestFirst) {
      sorts.push((a, b) => new Date(b.created) - new Date(a.created));
    }
    if (filterSwaps) {
      sorts.push((a, b) => b.swaps - a.swaps);
    }
    if (filterBondCurve) {
      sorts.push((a, b) => b.currentBondCurve - a.currentBondCurve);
    }
    if (filterHolders) {
      sorts.push((a, b) => b.holders.length - a.holders.length);
    }

    sorts.forEach(sort => {
      result.sort(sort);
    });

    setFilteredTokens(result);
  };

  const toggleSortOrder = () => {
    setIsNewestFirst(!isNewestFirst);
    setFilterTime(!filterTime);
  };
  const toggleFilterHolders = () => setFilterHolders(!filterHolders);
  const toggleFilterDevAmount = () => setFilterDevAmount(!filterDevAmount);
  const toggleFilterBondCurve = () => setFilterBondCurve(!filterBondCurve);
  const toggleFilterSwaps = () => setFilterSwaps(!filterSwaps);
  const handleSearchChange = (e) => setSearchMint(e.target.value);
  const handleClearSearch = () => setSearchMint('');

  return (
    <div className="filter-menu">
      <div className="filter-item">
        <label className="switch">
          <input type="checkbox" checked={filterTime && isNewestFirst} onChange={toggleSortOrder} />
          <span className="slider"></span>
        </label>
        <FaClock className="filter-icon" title="Sort by: Newest/Oldest"/>
      </div>
      <div className="filter-item">
        <label className="switch">
          <input type="checkbox" checked={filterHolders} onChange={toggleFilterHolders} />
          <span className="slider"></span>
        </label>
        <FaUsers className="filter-icon" title="Filter by Holders"/>
      </div>
      <div className="filter-item">
        <label className="switch">
          <input type="checkbox" checked={filterDevAmount} onChange={toggleFilterDevAmount} />
          <span className="slider"></span>
        </label>
        <FaUserTie className="filter-icon" title="Filter by Developer Amount"/>
      </div>
      <div className="filter-item">
        <label className="switch">
          <input type="checkbox" checked={filterBondCurve} onChange={toggleFilterBondCurve} />
          <span className="slider"></span>
        </label>
        <FaChartLine className="filter-icon" title="Filter by Bond Curve"/>
      </div>
      <div className="filter-item">
        <label className="switch">
          <input type="checkbox" checked={filterSwaps} onChange={toggleFilterSwaps} />
          <span className="slider"></span>
        </label>
        <FaExchangeAlt className="filter-icon" title="Sort by Number of Swaps"/>
      </div>
      <div className="filter-item search">
        <input type="text" placeholder="Search by Mint" value={searchMint} onChange={handleSearchChange} />
        <button onClick={handleClearSearch} className="clear-button">Clear</button>
      </div>
      <div className="live-tokens">
        <FaCoins className="live-tokens-icon" title="Live Tokens" />
        <span style={{color: 'white'}}>{allTokens.length}</span>
      </div>
    </div>
  );
};

export default FilterMenu;
